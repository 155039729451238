import * as React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import * as type from "styles/typography"
import theme from "styles/theme"

class MailchimpForm extends React.PureComponent {
  state = {
    email: "",
  }

  onChange = event => this.setState({ email: event.target.value })

  handleSubmit = e => {
    e.preventDefault()
    this.props.subscribe({ EMAIL: this.state.email })
  }

  render() {
    return this.props.status === "success"
      ? this.renderSuccess()
      : this.renderForm()
  }

  renderSuccess() {
    const { onSuccess, successMessage } = this.props
    onSuccess && onSuccess()

    return <div>{successMessage}</div>
  }

  renderForm() {
    const { status, message, subtext, buttonText } = this.props
    return (
      <div>
        <form onSubmit={this.handleSubmit} method="post">
          <div
            css={{
              display: "flex",
              alignItems: "stretch",
              margin: "30px 0 15px",
              opacity: status === "sending" ? 0.6 : 1,
              cursor: status === "sending" && "wait",
            }}
          >
            <input
              type="email"
              placeholder="Email Address"
              onChange={this.onChange}
              css={{
                border: `1px solid ${theme.camel}`,
                borderRadius: "3px 0 0 3px",
                appearance: "none",
                padding: "12px 15px",
                display: "block",
                outline: 0,
                margin: 0,
                flex: "1 1 70%",
                "&::placeholder": {
                  color: theme.n60,
                },
              }}
            />
            <input
              type="submit"
              value={buttonText || "Sign Up"}
              css={[
                type.buttonText,
                type.n100,
                {
                  appearance: "none",
                  background: theme.camel,
                  cursor: "pointer",
                  minWidth: type.ems(200),
                  border: 0,
                  borderRadius: "0 3px 3px 0",
                  display: "block",
                  margin: 0,
                  outline: 0,
                  flex: "1 1 200px",
                  [theme.mobile]: {
                    minWidth: 0,
                    flex: "1 1 auto",
                  },
                },
              ]}
            />
          </div>
          {status !== "sending" && message ? (
            <div
              css={[
                type.smallDetails,
                { color: "red", marginTop: -5, marginBottom: 5 },
              ]}
            >
              {message.replace(/[0-9]+ - /, "")}
            </div>
          ) : (
            <div css={type.smallDetails}>{subtext}</div>
          )}
        </form>
      </div>
    )
  }
}

export default ({ url, ...props }) => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <MailchimpForm
        subscribe={subscribe}
        status={status}
        message={message}
        {...props}
      />
    )}
  />
)
