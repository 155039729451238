import * as React from "react"

const { Provider, Consumer } = React.createContext()

export const injectCartItem = Component => props => (
  <Consumer>{context => <Component cartItem={context} {...props} />}</Consumer>
)

class CartItem extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      quantity: 1,
      subtotal: props.price,
    }
  }

  setQuantity = quantity =>
    this.setState({ quantity, subtotal: quantity * this.props.price })

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          ...this.props,
          setQuantity: this.setQuantity,
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export default CartItem
