import * as React from "react"
import { css } from "@emotion/core"
import * as type from "styles/typography"
import Container from "components/Container"
import Grid from "components/Grid"
import BuyButton from "components/BuyButton"
import SoldOut from "components/SoldOut"
import theme from "styles/theme"
import CartItem, { injectCartItem } from "components/CartItem"

const ProductSection = ({
  title,
  subtitle,
  intro,
  bullets,
  price,
  image,
  caption,
  details,
}) => (
  <div css={{ background: theme.n90, padding: "100px 30px" }}>
    <Container>
      <h2 css={[type.h2, type.mb2, type.centered]}>{title}</h2>
      <div css={[type.subheading, type.centered]}>{subtitle}</div>

      <ProductPanels>
        <InfoPanel>
          <p>{intro}</p>
          <ul css={type.list}>
            {bullets.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>

          <CartItem price={price}>
            <Price />
            <SoldOut productName={title} image={image}>
              <BuyButton productName={title} />
            </SoldOut>
          </CartItem>
        </InfoPanel>

        <ImagePanel>
          <Figure src={image} alt={title} caption={caption} />
          <Details details={details} />
        </ImagePanel>
      </ProductPanels>
    </Container>
  </div>
)

export default ProductSection

const ProductPanels = props => (
  <Grid
    css={css`
      align-items: center;
      max-width: 1224px;
      margin: 90px auto;

      ${theme.mobile} {
        margin: 60px auto;
      }
    `}
    {...props}
  />
)

const InfoPanel = props => (
  <div
    css={{
      gridColumn: "2 / span 5",
      [theme.mobile]: { gridColumn: 1, order: 2 },
    }}
    {...props}
  />
)

const ImagePanel = props => (
  <div
    css={{
      gridColumn: "7 / -1",
      [theme.mobile]: { gridColumn: 1, order: 1 },
    }}
    {...props}
  />
)

const Figure = ({ src, alt, caption }) => (
  <figure
    css={{
      margin: "0 auto",
    }}
  >
    <img src={src} alt={alt} />
    <figcaption css={[type.caption, type.n60, { maxWidth: 390 }]}>
      {caption}
    </figcaption>
  </figure>
)

const Price = injectCartItem(({ cartItem }) => (
  <div css={[type.h3, type.n30]}>${cartItem.subtotal.toFixed(2)}</div>
))

const Details = ({ details }) => (
  <div
    css={{
      display: "flex",
      marginTop: 30,
    }}
  >
    {details.map((node, index) => (
      <div
        css={[
          type.smallDetails,
          {
            "& + &": {
              marginLeft: 30,
              paddingLeft: 30,
              borderLeft: `1px solid ${theme.camel}`,
            },
          },
        ]}
        key={index}
      >
        {node}
      </div>
    ))}
  </div>
)
