export default {
  n100: "#FFFFFF",
  n90: "#F4F2F1",
  n60: "#9E8D7F",
  n30: "#5F5953",
  n20: "#3B3A38",
  camelLight: "#EED9C6",
  camel: "#E4AC7C",
  green: "#515F5E",
  black: "#111",
  white: "#fff",

  fontFamily: "Poppins, Helvetica, sans-serif",

  mobile: "@media (max-width: 960px)",
}
