import { css } from "@emotion/core"
import normalize from "emotion-normalize"
import theme from "styles/theme"
import * as type from "styles/typography"

export default css`
  ${normalize}

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${theme.fontFamily};
    background: ${theme.n100};
    font-size: 16px;

    @media (max-width: 500px) {
      font-size: 15px;
    }
    @media (max-width: 370px) {
      font-size: 14px;
    }
  }

  body {
    box-sizing: border-box;
    ${type.body};
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  button,
  input {
    font-family: ${theme.fontFamily};
    margin-top: 0;
    margin-bottom: 0.2em;
  }

  img {
    max-width: 100%;
  }
`
