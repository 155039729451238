import * as React from "react"
import { injectSoldOut } from "components/SoldOut"
import { injectCartItem } from "components/CartItem"
import * as type from "styles/typography"
import theme from "styles/theme"
import arrow from "images/icons/dropdown-arrow.svg"
import trackEvent from "lib/track-event"

class BuyButton extends React.PureComponent {
  updateQuantity = event => {
    this.props.cartItem.setQuantity(event.target.value)
  }

  handleClick = () => {
    const value = Math.round(
      this.props.cartItem.quantity * this.props.cartItem.price
    )
    trackEvent("Buy Button Clicked", this.props.productName, value)
    this.props.soldOut.open()
  }

  render() {
    return (
      <>
        <div
          css={{ margin: "30px 0 8px", display: "flex", alignItems: "stretch" }}
        >
          <select
            css={{
              border: `1px solid ${theme.camel}`,
              borderRadius: "3px 0 0 3px",
              appearance: "none",
              padding: "15px 50px 15px 20px",
              display: "block",
              backgroundImage: `url(${arrow})`,
              backgroundSize: "18px auto",
              backgroundPosition: "right 15px center",
              backgroundRepeat: "no-repeat",
              outline: 0,
            }}
            value={this.props.cartItem.quantity}
            onChange={this.updateQuantity}
          >
            {Array.apply(null, { length: 10 }).map((_, index) => (
              <option value={index + 1} key={index}>
                {index + 1}
              </option>
            ))}
          </select>
          <button
            css={[
              type.buttonText,
              type.n100,
              {
                appearance: "none",
                background: theme.camel,
                cursor: "pointer",
                minWidth: type.ems(200),
                border: 0,
                borderRadius: "0 3px 3px 0",
                display: "block",
                margin: 0,
                outline: 0,
              },
            ]}
            onClick={this.handleClick}
          >
            Buy Now
          </button>
        </div>

        <div css={type.smallDetails}>
          Secure Payment Gateway · Free Shipping
        </div>
      </>
    )
  }
}

export default injectCartItem(injectSoldOut(BuyButton))
