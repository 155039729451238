import * as React from "react"
import * as type from "styles/typography"
import theme from "styles/theme"
import Container from "components/Container"
import MailchimpForm from "components/MailchimpForm"

const NewsletterSignup = ({ title, copy, subtext }) => (
  <div
    css={{
      background: theme.camelLight,
      padding: `${type.ems(75)} 20px`,
    }}
  >
    <Container max={600}>
      <h2 css={[type.h2x, type.n20, type.centered, type.mb3]}>{title}</h2>
      <p>{copy}</p>

      <MailchimpForm
        url="https://canvas1839.us20.list-manage.com/subscribe/post?u=3d1d1aa73a643323a804570f1&id=9e47509e18"
        subtext={subtext}
        successMessage="Thank you! You’ve been added to our list and will be among the first to hear when we launch new products and scents."
      />
    </Container>
  </div>
)

export default NewsletterSignup
