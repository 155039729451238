import * as React from "react"
import Layout from "components/Layout"
import Header from "components/Header"
import Footer from "components/Footer"
import Hero from "components/Hero"
import ProductSection from "components/ProductSection"
import Foldout, { List as FoldoutList } from "components/Foldout"
import Testimonials, { Testimonial } from "components/Testimonials"
import ShortText from "components/ShortText"
import LongText from "components/LongText"
import NewsletterSignup from "components/NewsletterSignup"

import hempCream from "images/hemp-relief-cream.jpg"
import jay from "images/headshots/jay.jpg"
import andrea from "images/headshots/andrea.jpg"
import sam from "images/headshots/sam.jpg"
import ourStory from "images/our-story.jpg"

const Page = () => (
  <Layout>
    <Header />

    <Hero title="Original Medicine" subtitle="The art and science of CBD" />

    <ProductSection
      title="Hemp Relief Cream"
      subtitle="Pure Hemp Cannabidiol (CBD)"
      intro="Our luxurious hemp relief cream naturally relieves aches and pains and supports relaxation, leaving a clean skin feel and no residue whatsoever."
      bullets={[
        "Non-psychoactive and 100% legal",
        "2X as concentrated as other brands",
        "Ultra-fast uptake through skin",
        "Total utilization, no wasted CBD",
        "Never any parabens, dyes, sulfates or phthalates",
      ]}
      price={50}
      image={hempCream}
      caption="We include third party batch test results in every product box to help ensure purity and safety."
      details={[
        <>
          500MG CBD isolate
          <br />
          Less than 0.3% THC
        </>,
        <>
          Net Wt. 2.0oz <br />
          57g 59mL
        </>,
      ]}
    />

    <Foldout title="Details About This Product">
      <FoldoutList
        title="Free of"
        items={[
          "Fillers",
          "Formaldehyde",
          "Paraben",
          "Dye",
          "Sulfate",
          "Mineral Oil",
          "Phthalate",
          "Animal-Derived Ingredients",
          "Animal Testing",
        ]}
      />

      <FoldoutList
        title="Benefits"
        items={[
          "A sense of calm and focus",
          "Relief from everyday stresses",
          "Help in recovery from exercise-induced inflammation",
          "Eases aches and pains",
        ]}
      />
    </Foldout>

    <ShortText title={<span>Science You Can&nbsp;Trust</span>}>
      <p>
        Our pharmaceutical-grade patent portfolio is unique in the CBD industry.
        Activene™ ensures the highest molecular uptake possible. Canvas 1839 is
        the most bioavailable product on the market today.
      </p>
      <p>
        Our team of PhDs is led by Chief Science Officer and Stanford grad Dr.
        Kyle Hammerick, whose pioneering work in Nanoscale Biology has myriad
        applications in CBD.
      </p>
      <p>
        Browse some of Kyle’s{" "}
        <a
          href="https://www.ncbi.nlm.nih.gov/pubmed/?term=Hammerick+KE%5BAuthor%5D"
          target="_blank"
          rel="noopener noreferrer"
        >
          peer-reviewed research here
        </a>
        .
      </p>
    </ShortText>

    <Testimonials title="Testimonials">
      <Testimonial
        image={jay}
        name="Jay R."
        title="Executive Assistant"
        location="Austin, TX"
        quote="“I sleep, I mean...I usually spiral late at night in my head and this stuff just takes edge off."
      />
      <Testimonial
        image={andrea}
        name="Andrea W."
        title="Sales"
        location="Austin, TX"
        quote="This lotion rules. I can’t wait for a tincture!"
      />
      <Testimonial
        image={sam}
        name="Sam P."
        title="Advertising Executive"
        location="Atlanta, GA"
        quote="I applied the cream on my neck and shoulders before bed. I am sleeping better than I have in months."
      />
    </Testimonials>

    <LongText title="Our Story" image={ourStory}>
      <p>In 1839 two extraordinary things happened.</p>
      <p>
        First, William Brooke O’Shaughnessy, an Irish physician famous for his
        wide-ranging work in pharmacology and chemistry, introduced the
        therapeutic use of hemp to Western medicine for the first time with a
        groundbreaking article in London’s Provincial Medical Journal and
        Retrospect of the Medical Sciences.
      </p>
      <p>
        In the very same year, the inaugural Texas Congress formed a commission
        to seek a site for a new capital to be named for Stephen F. Austin.
      </p>
      <p>
        Mirabeau B. Lamar, second president of the newly formed Republic of
        Texas, advised the commissioners to investigate the area named Waterloo,
        noting the area’s hills, waterways, and pleasant surroundings. Waterloo
        was selected, and Austin was chosen as the town’s new name.
      </p>
      <p>
        Canvas 1839 is a community brought together by the restorative power of
        Hemp Cannabidiol (CBD). We believe deeply in the ritual of wellness. Our
        mission is to take better care of ourselves, each other, and the world
        around us. This is original medicine.
      </p>
      <br />
      <p>Crafted with ❤️ in Colorado and Austin,&nbsp;TX.</p>
    </LongText>

    <NewsletterSignup
      title="We’re just getting started"
      copy="Stay in touch for updates on new products and scents as well as brick and mortar retail availability."
      subtext="We never, ever spam or sell your information. We email about once a month."
    />

    <Footer
      socials={{
        facebook: "https://www.facebook.com/pg/CANVAS1839",
        twitter: "https://twitter.com/CANVAS1839/",
        instagram: "https://www.instagram.com/CANVAS1839/",
      }}
      copyright="©2019 Canvas 1839"
    />
  </Layout>
)

export default Page
