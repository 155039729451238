import * as React from "react"
import * as type from "styles/typography"
import theme from "styles/theme"
import Container from "components/Container"
import Grid from "components/Grid"

const LongText = ({ title, image, children }) => (
  <div>
    <Container max={1224}>
      <Grid css={{ alignItems: "center", margin: "0 auto" }}>
        <div
          css={{
            gridColumn: "1 / 5",
            transform: "translateX(-80px)",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
            [theme.mobile]: {
              display: "none",
            },
          }}
        />

        <div
          css={{
            gridColumn: "5 / -2",
            padding: `${type.ems(100)} 20px`,
            [theme.mobile]: {
              gridColumn: 1,
            },
          }}
        >
          <h2 css={[type.h2x, type.mb5]}>{title}</h2>
          {children}
        </div>
      </Grid>
    </Container>
  </div>
)

export default LongText
