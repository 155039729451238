import * as React from "react"
import * as type from "styles/typography"
import theme from "styles/theme"
import Container from "components/Container"

const Testimonials = ({ title, children }) => (
  <div css={{ background: theme.n90, padding: `${type.ems(80)} 20px` }}>
    <Container max={1245}>
      <h2 css={[type.h2x, type.centered, type.mb5]}>{title}</h2>
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          "@media (max-width: 700px)": {
            flexDirection: "column",
          },
        }}
      >
        {children}
      </div>
    </Container>
  </div>
)

export default Testimonials

export const Testimonial = ({ image, name, title, location, quote }) => (
  <div
    css={{
      flex: "1 1",
      textAlign: "center",
      margin: "0 auto 60px",
      maxWidth: 415,
      padding: "0 37px",
    }}
  >
    <img
      src={image}
      alt={name}
      css={{
        display: "block",
        margin: "0 auto",
        borderRadius: "50%",
        marginBottom: type.ems(40),
      }}
    />

    <div
      css={{
        marginBottom: 20,
        "&:after": {
          content: '""',
          width: 72,
          display: "block",
          margin: "10px auto",
          borderBottom: `1px solid ${theme.camel}`,
        },
      }}
    >
      <div css={type.bold}>{name}</div>
      <div css={{}}>{title}</div>
      <div css={type.italic}>{location}</div>
    </div>

    <div css={type.italic}>“{quote}”</div>
  </div>
)
