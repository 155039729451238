import theme from "styles/theme"

const root = 16

export const mob = (...args) => ({ [theme.mobile]: [...args] })
export const ems = value => `${value / root}rem`

// ---- Swatches
const fsA = { fontSize: ems(72) }
const fsB = { fontSize: ems(48) }
const fsC = { fontSize: ems(42) }
const fsD = { fontSize: ems(30) }
const fsE = { fontSize: ems(24) }
const fsF = { fontSize: ems(20) }
const fsG = { fontSize: ems(18) }
const fsH = { fontSize: ems(16) }
const fsI = { fontSize: ems(14) }

const lh1 = { lineHeight: 1 }
const lh13 = { lineHeight: 1.3 }
const lh14 = { lineHeight: 1.4 }
const lh15 = { lineHeight: 1.5 }
const lh16 = { lineHeight: 1.6 }

export const regular = { fontWeight: 400 }
export const medium = { fontWeight: 500 }
export const bold = { fontWeight: 700 }
export const italic = { fontStyle: "italic" }

export const n20 = { color: theme.n20 }
export const n30 = { color: theme.n30 }
export const n60 = { color: theme.n60 }
export const n90 = { color: theme.n90 }
export const n100 = { color: theme.n100 }
export const camel = { color: theme.camel }
export const camelLight = { color: theme.camelLight }
export const green = { color: theme.green }

export const h1 = [fsA, lh13, medium, mob(fsB)]
export const h1Sub = [fsD, lh13, regular, mob(fsE)]
export const h2 = [fsC, lh13, medium, green]
export const h2x = [fsD, lh13, medium, camel]
export const h3 = [fsE, lh13, medium, green, mob(fsF)]

export const list = [
  fsH,
  lh14,
  { margin: "2em 0 2em 1.25em", padding: 0, li: { marginBottom: 11 } },
  mob(fsI),
]

export const subheading = [fsF, lh13, regular, n30]

export const smallDetails = [fsI, lh16, regular, n30]

export const caption = [fsI, lh15, italic]

export const buttonText = [
  fsF,
  lh1,
  bold,
  { textTransform: "uppercase", letterSpacing: 1 / 20 },
]

export const body = [fsG, lh16, regular, n20, mob(fsH)]

// ---- Utilities

export const centered = { textAlign: "center" }
export const mb1 = { marginBottom: "0.2em" }
export const mb2 = { marginBottom: "0.5em" }
export const mb3 = { marginBottom: "0.8em" }
export const mb4 = { marginBottom: "1em" }
export const mb5 = { marginBottom: "1.5em" }
