import * as React from "react"
import Container from "components/Container"
import theme from "styles/theme"
import * as type from "styles/typography"

const ShortText = ({ title, children, ...props }) => (
  <Container
    max={700}
    css={{
      marginTop: type.ems(140),
      marginBottom: type.ems(140),
      padding: "0 20px",
      position: "relative",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: type.ems(-190),
        left: "50%",
        marginLeft: -1,
        width: 2,
        height: type.ems(100),
        background: theme.camel,
      },
    }}
    {...props}
  >
    <h2 css={[type.h2, type.centered, type.mb4]}>{title}</h2>
    <div>{children}</div>
  </Container>
)

export default ShortText
