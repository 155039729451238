import * as React from "react"
import { jsx } from "@emotion/core"
import { ReactComponent as FacebookIcon } from "simple-icons/icons/facebook.svg"
import { ReactComponent as TwitterIcon } from "simple-icons/icons/twitter.svg"
import { ReactComponent as InstagramIcon } from "simple-icons/icons/instagram.svg"
import Container from "components/Container"
import theme from "styles/theme"

import canvasLogo from "images/logo-small.svg"

const socialIcons = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
}

const icon = {
  width: 40,
  height: 40,
  padding: 10,
  fill: theme.green,
  marginLeft: 10,
  transition: "opacity 250ms",
  "&:hover": {
    opacity: 0.8,
  },
}

const Footer = ({ socials, copyright }) => (
  <Container
    max={1224}
    css={{
      textAlign: "center",
      padding: "30px 20px 45px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      lineHeight: 1,
      [theme.mobile]: {
        display: "block",
      },
    }}
  >
    <div css={{ flex: "1 0 175px" }} />

    <img src={canvasLogo} alt="Canvas 1839" css={{ width: 233 }} />

    <div
      css={{
        flex: "1 0 175px",
        textAlign: "right",
        [theme.mobile]: {
          textAlign: "center",
        },
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "flex-end",
          [theme.mobile]: {
            margin: 30,
            justifyContent: "center",
          },
        }}
      >
        {Object.entries(socials).map(([key, href]) => (
          <a href={href} target="_blank" rel="noopener noreferrer" key={href}>
            {jsx(socialIcons[key], { alt: key, css: icon })}
          </a>
        ))}
      </div>
      <small
        css={{
          fontSize: 12,
          fontWeight: 500,
          textTransform: "uppercase",
          letterSpacing: 1,
          color: theme.n60,
        }}
      >
        {copyright}
      </small>
    </div>
  </Container>
)

export default Footer
