import * as React from "react"
import PropTypes from "prop-types"
import Dialog from "components/Dialog"
import * as type from "styles/typography"
import MailchimpForm from "components/MailchimpForm"

const { Provider, Consumer } = React.createContext()

export const injectSoldOut = Component => props => (
  <Consumer>{context => <Component soldOut={context} {...props} />}</Consumer>
)

class SoldOut extends React.Component {
  state = {
    open: false,
    submitted: false,
  }

  open = () => {
    this.setState({ open: true })
  }

  close = () => {
    this.setState({ open: false })
  }

  submitted = () => {
    this.setState({ submitted: true })
  }

  render() {
    return (
      <Provider
        value={{
          open: this.open,
          close: this.close,
        }}
      >
        <Dialog
          isOpen={this.state.open}
          onRequestClose={this.close}
          contentLabel="Product Sold Out"
        >
          <img
            src={this.props.image}
            alt={this.props.productName}
            css={{
              width: "100%",
              maxHeight: 250,
              padding: 30,
              objectFit: "contain",
              objectPosition: "center",
            }}
          />

          {this.state.submitted ? (
            <>
              <h2 css={[type.h2, type.centered]}>Thank you!</h2>
              <p>
                You’ve been added to our list and will be among the first to
                hear when we’re back in stock.
              </p>
              <p>
                As a special treat, we’ll send you a 15% off coupon to use at
                checkout on your next purchase.
              </p>
            </>
          ) : (
            <>
              <h2 css={[type.h3, type.centered]}>
                Unfortunately This Item Is Sold Out.
              </h2>
              <p css={[type.n30, type.centered]}>
                Be notified when this product is back in stock. We’ll send you a{" "}
                <strong>15% off coupon</strong>. Thank you for your patience!
              </p>

              <MailchimpForm
                buttonText="Notify Me"
                url="https://canvas1839.us20.list-manage.com/subscribe/post?u=3d1d1aa73a643323a804570f1&id=a92013f633"
                subtext="We will email you once this product is back in stock. We never spam or sell your information."
                onSuccess={this.submitted}
              />
            </>
          )}
        </Dialog>
        {this.props.children}
      </Provider>
    )
  }
}

SoldOut.propTypes = {
  productName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}

export default SoldOut
