import * as React from "react"
import * as type from "styles/typography"
import Container from "components/Container"

import heroBg from "images/hero-background-x.jpg"

const Hero = ({ title, subtitle }) => (
  <Container
    css={[
      type.centered,
      type.n100,
      {
        padding: "100px 30px",
        backgroundImage: `url(${heroBg})`,
        backgroundPosition: "center right",
        backgroundSize: "cover",
      },
    ]}
  >
    <h1 css={[type.h1, type.mb1, type.mob(type.mb2)]}>Original Medicine</h1>
    <div css={type.h1Sub}>The art and science of CBD</div>
  </Container>
)

export default Hero
