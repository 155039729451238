import * as React from "react"
import theme from "styles/theme"

const Grid = props => (
  <div
    css={{
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: 24,
      [theme.mobile]: {
        gridTemplateColumns: "1fr",
        maxWidth: 600,
      },
    }}
    {...props}
  />
)

export default Grid
