import * as React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { css, ClassNames } from "@emotion/core"
import theme from "styles/theme"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

const CloseIcon = props => (
  <PlusIcon
    css={{
      transform: "rotate(45deg)",
      position: "absolute",
      right: 15,
      top: 15,
      width: 30,
      cursor: "pointer",
    }}
    {...props}
  />
)

Modal.setAppElement("#___gatsby")

const modalContainerStyles = css`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  outline: 0;
  padding: 36px;
  position: relative;
  background: ${theme.n90};
  border: 3px solid #b09e8f;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 5px 20px 5px hsla(27, 7%, 60%, 0.3);

  @media (max-width: 600px) {
    padding: 24px;
    border: 0;
  }
`

const Dialog = ({ children, onRequestClose, ...props }) => (
  <ClassNames>
    {({ css, cx }) => {
      const overlayStyles = css`
        overflow-y: auto;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: hsla(27, 9%, 90%, 0.7);
      `

      const noScroll = css`
        overflow: hidden;

        // iOS-only
        @supports (-webkit-overflow-scrolling: touch) {
          position: fixed;
        }
      `

      return (
        <Modal
          css={modalContainerStyles}
          overlayClassName={cx(css(Modal.defaultStyles.overlay), overlayStyles)}
          bodyOpenClassName={noScroll}
          onRequestClose={onRequestClose}
          {...props}
        >
          <CloseIcon onClick={onRequestClose} />
          {children}
        </Modal>
      )
    }}
  </ClassNames>
)

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  contentLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Dialog
