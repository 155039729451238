import * as React from "react"
import * as type from "styles/typography"
import theme from "styles/theme"
import Container from "components/Container"

import plus from "images/icons/plus.svg"

class Foldout extends React.PureComponent {
  state = {
    open: false,
  }

  toggleOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  render() {
    return (
      <Wrapper>
        <Header
          title={this.props.title}
          onClick={this.toggleOpen}
          isOpen={this.state.open}
        />
        <Content isOpen={this.state.open}>{this.props.children}</Content>
      </Wrapper>
    )
  }
}

export default Foldout

const Wrapper = ({ children, containerProps, ...props }) => (
  <div
    css={{
      background: theme.n90,
      padding: "0 20px 80px",
    }}
    {...props}
  >
    <Container
      max={1016}
      css={{
        borderTop: `2px solid ${theme.camel}`,
        borderBottom: `2px solid ${theme.camel}`,
        padding: `${type.ems(18)} 0`,
      }}
      {...containerProps}
    >
      {children}
    </Container>
  </div>
)

export const Header = ({ title, onClick, isOpen }) => (
  <header
    css={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <h3 css={type.h3}>{title}</h3>

    <img
      src={plus}
      alt="Toggle Open"
      css={{
        transition: "transform 400ms",
        transitionDelay: "300ms",
        transform: isOpen && "rotate(45deg)",
        width: type.ems(28),
      }}
    />
  </header>
)

export const Content = ({ isOpen, ...props }) => (
  <div
    css={{
      transition: "max-height 400ms",
      overflow: "hidden",
      maxHeight: isOpen ? 800 : 0,
    }}
  >
    <div
      css={{
        margin: "80px 0 30px",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridGap: 24,
        "@media (max-width: 530px)": {
          display: "block",
        },
      }}
      {...props}
    />
  </div>
)

export const List = ({ title, items, ...props }) => (
  <div
    css={{
      display: "grid",
      gridTemplateColumns: "2fr 3fr",
      gridGap: 24,
      [theme.mobile]: {
        display: "block",
      },
      "@media (max-width: 530px)": {
        display: "grid",
        marginBottom: 30,
      },
    }}
    {...props}
  >
    <div>
      <h3 css={[type.h3, type.mb4]}>{title}</h3>
    </div>
    <ul css={[type.list, { margin: 0, color: theme.n30, listStyle: "none" }]}>
      {items.map((text, index) => (
        <li key={index}>{text}</li>
      ))}
    </ul>
  </div>
)
