import * as React from "react"

import canvasLogo from "images/logo-small.svg"

const Header = () => (
  <div
    css={{
      textAlign: "center",
      padding: 24,
    }}
  >
    <img src={canvasLogo} alt="Canvas 1839" css={{ width: 233 }} />
  </div>
)

export default Header
