import * as React from "react"
import Helmet from "react-helmet"
import { Global } from "@emotion/core"
import Favicons from "favicons"
import baseStyles from "styles/base"

const title = "Canvas 1839: Original Medicine"
const description =
  "Canvas 1839 is a community brought together by the restorative power of CBD. We create superior CBD products including creams and tinctures."

const Layout = ({ children }) => (
  <div>
    <Global styles={baseStyles} />
    <Helmet title={title}>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={`${process.env.GATSBY_HOST}/opengraph.jpg`}
      />
      <meta property="og:image:width" content={1200} />
      <meta property="og:image:height" content={630} />
    </Helmet>
    <Favicons />

    {children}
  </div>
)

export default Layout
