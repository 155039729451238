import * as React from "react"

const Container = ({ children, max, ...props }) => (
  <div
    css={{
      maxWidth: max || 1440,
      margin: "0 auto",
    }}
    {...props}
  >
    {children}
  </div>
)

export default Container
